<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>密码修改</p>
    </div>
    <div class="headerR">
       <a href="javascript:;" @click="updatePwd">完成</a>
    </div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="kbox"></div>
<div class="addressDiv">
  <div class="addiv1">
    <div class="addiv1_l">旧密码：</div>
    <div class="addiv1_r"><input name=""  v-model="paramsuPw.oldPwd" type="text" /></div>
  </div>
  <div class="addiv1">
    <div class="addiv1_l">新密码：</div>
    <div class="addiv1_r"><input name="" v-model="paramsuPw.newPwd" type="text" /></div>
  </div>
  <div class="addiv1">
    <div class="addiv1_l">确认密码：</div>
    <div class="addiv1_r"><input name="" v-model="paramsuPw.confirmps" type="text" /></div>
  </div>
</div>
<div class="clear"></div>
<div class="kbox"></div>
</template>

<script>
import memberModel from "@/model/memberModel";
export default {
  setup() {
    const { paramsuPw, updatePwd } = memberModel();
    return {
      paramsuPw,
      updatePwd,
    };
  },
};
</script>

<style>
</style>