<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>设置</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="jsyhq pad0">
  <div class="mytb">
    <router-link to="/memberInfo" >
    <div class="mytbL">
      <img :src="(options.item.logo==null||options.item.logo=='')?'images/tx.png':options.item.logo.replace('/http://','http://')" />
    </div>
    <div class="mytbC">
      <p class="p1">{{options.item.name}}</p>
      <p class="p2">会员名：{{options.item.name}}</p>
    </div>
    <div class="mytbR">
      <img src="images/more.png">
    </div>
    <div class="clear"></div>
    </router-link>
  </div>
  <div class="jsyhq_2 bor0">
     <router-link to="/addressList" >
    <div class="jsjfL">
      <p>我的收货地址</p>
    </div>
    <div class="jsjfR">
      <img src="images/more.png">
    </div>
    <div class="clear"></div>
     </router-link>
  </div>
  <div class="kbox"></div>
  <div class="jsyhq_2 bor0">
    <router-link to="/settingPassword" >
    <div class="jsjfL">
      <p>账户与安全</p>
    </div>
    <div class="jsjfR">
      <img src="images/more.png">
    </div>
  </router-link>
  </div>
  <div class="kbox"></div>
  <!-- <div class="jsyhq_2">
    <div class="jsjfL">
      <p>地区设置</p>
    </div>
    <div class="jsjfR">
      <img src="images/more.png">
    </div>
  </div> -->
  <div class="jsyhq_2">
    <div class="jsjfL">
      <p>关于</p>
    </div>
    <div class="jsjfR">
      <img src="images/more.png">
    </div>
  </div>
</div>
<div class="clear"></div>
<div class="tuichu">
  <a href="javascript:;" @click="logout">退出当前帐户</a>
</div>

</template>

<script>
import memberModel from '@/model/memberModel'
export default {
  name:'memberSettingPage',
    setup(){
      const {options,getInfo,logout}=memberModel();
      getInfo();
      return {
        options,
        getInfo,
        logout
      }
    }
};
</script>

<style>
</style>